<script>
import {
  LayoutIcon,
  LifeBuoyIcon,
  ServerIcon,
  RssIcon
} from "vue-feather-icons";
export default {
  components: { LayoutIcon, LifeBuoyIcon, ServerIcon, RssIcon }
};
</script>

<template>
  <div>
    <!-- Referidos Start -->
    <section
      class="section feather-bg-img"
      :style="{'background-image': 'url(' + require('@/assets/images/features-bg-img-1.png') + ')'}"
      id="privacidad"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="text-center mb-5">
              <h3 class="title mb-3">Aviso de Privacidad</h3>
              <p class="text-left text-muted font-size-15">
                Valoramos a nuestros socios, por eso ofrecemos las condiciones más favorables a los más activos.
                El aumento del monto del pago a los socios depende de la cantidad de referencias activas.
                <br/>Cuantas más referencias activas atraiga, mayores serán sus ingresos.
                <br/>Cualquiera puede atraer nuevas referencias; solo necesita crear una cuenta para esto.
              </p>
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-lg-5">
            <!-- <h5 class="font-weight-bold mb-2 wc-title mt-4" style="margin-bottom: 1.5rem !important;">Su porcentaje será:</h5>
            <p class="font-weight-medium text-uppercase mb-2">
              <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> <b>7% Nivel 1</b>
            </p>
            <p class="font-weight-medium text-uppercase mb-2">
              <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> <b>5% Nivel 2</b>
            </p>
            <p class="font-weight-medium text-uppercase mb-2">
              <i class="mdi mdi-chart-bubble h2 text-primary mr-1 align-middle"></i> <b>3% Nivel 3</b>
            </p> -->
          </div>
          <div class="col-lg-6 offset-lg-1">
            <!-- <div class="mt-4 mt-lg-0">
              <img src="@/assets/images/referidos-img.png" alt class="img-fluid d-block mx-auto" style="height: 300px;"/>
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <!-- Referidos End -->
  </div>
</template>