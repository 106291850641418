<script>
import Navbar2 from "@/components/navbar2";
import Privacity from "@/components/privacidad";
import Footer from "@/components/footer";

/**
 * Publicaciones
 */
export default {
  components: { Navbar2, Privacity, Footer }
};
</script>

<template>
  <div>
    <Navbar2 />
    <div v-scroll-spy>
      <Privacity />
      <Footer />
    </div>
  </div>
</template>